import React, { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { screenSize } from '../util/screenSize';
import '../styles/ProfilePage.css';





const Rewards = () => {

  const { userData } = useSelector((store) => store.userReducer);


  var screen = window.innerWidth;




console.log('userData', userData)


  return (
    <div className="ProfilePage">
      <Navbar activeMenuItem={"profile"} />
      {screen > screenSize ? (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>My Rewards</h1>
            {/* <span
              onClick={() => history('/profile/edit')}
              className='editProfileSpan'
            >
              My Progress
            </span> */}
          </div>
          <div className="contact-details-div">
            <span className="profile-name">Joined on</span>
            <span>12/04/2021</span>
          </div>
        </div>
      ) : (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>
              {userData?.firstname} {userData?.lastname}
            </h1>
            <div className="contact-details-div">
              <span className="profile-name">{userData?.phone}</span>
              <span>{userData?.email}</span>
            </div>
          </div>
        </div>
      )}
      <div className="Profile-card-container">
        {/* first card */}
        <div className="Profile-card first-card">
        
 

<div className="d-flex justify-content-around">
    <div>  <h5>Welcome Bonus</h5>
    <span>{userData?.welcomeBonus}</span></div>
  
    <div>  <h5>Loyalty Points</h5>
    <span>{userData?.loyaltyPoints}</span></div></div>

          
        </div>




      </div>
      <Footer />
    </div>
  );
};

export default Rewards;
