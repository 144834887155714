import * as api from './api';
import { SIGNUP, LOGIN, SET_USER_DETAILS, LOGOUT } from '../actionsType';
import {toast} from 'react-toastify'

export const signup = (signupData, history) => async (dispatch) => {
  try {
    const { data } = await api.signup(signupData);
    dispatch({
      type: SIGNUP,
      payload: { userDetails: data.result, token: data.accessToken },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const login = (loginData) => async (dispatch) => {
  try {
    const { data } = await api.login(loginData);
    console.log("rsult",data.result)
    console.log("token",data.accessToken)
    dispatch({
      type: LOGIN,
      payload: { userDetails: data.result, token: data.accessToken },
    });
  return data
  } catch (err) {
   
    throw err;
  }
};

export const logout = (history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
    history('/');
  } catch (err) {
    console.log('something went wrong');
  }
};

export const setUserDetails = (history) => async (dispatch) => {
  try {
    const user = await api.getUser();

   // console.log(user.data)
    dispatch({
      type: SET_USER_DETAILS,
      payload: user.data,
    });

   // console.log(history)
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateUserDetails = (updates) => async (dispatch) => {
  try {
    const { data } = await api.updateUserDetails(updates);
    dispatch({
      type: SET_USER_DETAILS,
      payload: data.user,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};
