import React, { Fragment } from 'react';
import '../styles/TransactionCard.css';

// const options = { year: 'numeric', month: 'short', day: '2-digit' };

const WorkshopTransactionCard = (props) => {
  const { userWorkshops, handleDownloadInvoice } = props;
  return (
    <Fragment>
      {userWorkshops?.map((workshop) => {
        return (
          <div className='TransactionCard'>
            <div className='TransactionCard-row-1'>
              <p className='first-col'>Workshop: {`${workshop.topic}`}</p>
              <p className='second-col'>₹{workshop.price}</p>
              <p className='third-col'>
                {
                  workshop.UserWorkshops[workshop.UserWorkshops.length - 1]
                    .purchasedOn
                }
              </p>
            </div>
            <div className='TransactionCard-row-2'>
              <p className='all-chapters-text'>
                Start date: {workshop.start_date}
              </p>
              <p
                className='download-text'
                onClick={() => handleDownloadInvoice('workshop', workshop)}
              >
                Download receipt
              </p>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default WorkshopTransactionCard;
