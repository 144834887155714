import * as api from './api';
import {  TEACHER_LOGIN, SET_TEACHER_DETAILS, TEACHER_LOGOUT } from '../actionsType';

  export const teacher_login = (teacherLoginData) => async (dispatch) => {
    try {
      const { data } = await api.teacher_login(teacherLoginData);
   // console.log(data)
      dispatch({
        type: TEACHER_LOGIN,
        payload: { teacherDetails: data.result, token: data.accessToken },
        
      });
      return data;
    
    } catch (err) {
      console.error(err);
      throw err;  
    }
  };


  export const setTeacherDetails = (history) => async (dispatch) => {
    try {
      const teacher = await api.getTeacher();
      
     // console.log(teacher)
      dispatch({
        type: SET_TEACHER_DETAILS,
        payload: teacher.data,
      });

    // history("/teacher-home")
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : 'Something went in teacher details  wrong';
      console.log(message);
    }
  };

  export const teacher_logout = (history) => async (dispatch) => {
    try {
      dispatch({
        type: TEACHER_LOGOUT,
      });
      history('/');
    } catch (err) {
      console.log('something went wrong');
    }
  };