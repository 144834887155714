import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from '../../pages/Parents/Dashboard'
import { Ranking } from "../../pages/Parents/Ranking";
import LiveClassParents from "../../pages/Parents/LiveClassParents";
import TestMCQ from "../../pages/Parents/Exams/TestMCQ";
import TestMega from "../../pages/Parents/Exams/TestMega";
import TestSubjective from "../../pages/Parents/Exams/TestSubjective";
import Reports from "../../pages/Parents/Reports";
import ViewReport from "../../pages/Parents/ViewReport";

const ParentRoutes = () => {
  return (
    <Routes>
      <Route exact path="/:id/dashboard" element={<Dashboard/>} />
      <Route exact path="/ranking" element={<Ranking/>} />
      <Route exact path="/liveclass" element={<LiveClassParents/>} />
      <Route exact path="/mcq" element={<TestMCQ/>} />
      <Route exact path="/mega" element={<TestMega/>} />
      <Route exact path="/subjective" element={<TestSubjective/>} />
      <Route exact path="/reports" element={<Reports/>} />
      <Route exact path="/reports/:id" element={<ViewReport/>} />
  


    
    </Routes>
  );
};

export default ParentRoutes;
