import { PARENTS_SIGN_IN, PARENTS_SIGN_UP} from '../actionsType';
import * as api from './api';



export const parentSignup = (signupData, history) => async (dispatch) => {
  try {
    const { data } = await api.parent_signup(signupData);
    dispatch({
      type: PARENTS_SIGN_UP,
      payload: { parentDetails: data.result, token: data.accessToken },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const parentsLogin = (parentLoginData) => async (dispatch) => {
  try{
    const {data}=await api.parents_login(parentLoginData)
    console.log('data from parents login',data)
    dispatch({
    type:PARENTS_SIGN_IN,
    payload: { parentsDetails: data?.result, token: data.accessToken },
  });
  return data;

} catch (err) {
  console.error(err);
  throw err;
  }
};
