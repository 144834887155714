import React from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { getMCQExams } from "../../../../api/addExam/MCQ/mcq_exam_api";
import { QueryKeys } from "../../../../utils/QueryKeys";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { useQuery } from "@tanstack/react-query";
// import MCQTable from "./MCQTable";
import Navbar from "../../../../components/Navbar";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import DashboardScreen from "./DashboardScreen";
import { useNavigate } from "react-router-dom";
const ExamDashboard = () => {
  const navigate = useNavigate();

  const handleNavigateMCQ = () => {
    navigate("/student-test/student_mcq-test");
  };
  const handleNavigateSub = () => {
    navigate("/student-test/student_subjective-test");
  };

  const handleNavigateMega = () => {
    navigate("/student-test/student_mega-test");
  };

  // Use React Query to fetch mcq exam data
  const {
    data: mcqExamData,
    error: mcqExamError,
    isLoading: mcqExamIsLoading,
    isError: mcqExamIsError,
  } = useQuery({
    queryKey: QueryKeys.mcq_exams,
    queryFn: getMCQExams,
  });

  if (mcqExamIsLoading) {
    return (
      <Grid align="center" sx={{ marginTop: "10px" }}>
        <CircularProgress sx={{ color: "#20209f" }} />
      </Grid>
    );
  }

  if (mcqExamIsError) {
    return <p>Error: {mcqExamError.message}</p>;
  }

  //   filter mcq test
  // const mcqData = mcqExamData?.filter(
  //   (item) => item?.testType.toLowerCase() === "mcq"
  // );

  // Fetch today's date and time
  const now = new Date();
  const currentDate = now.toISOString().split("T")[0];
  const currentTime = now.toTimeString().split(" ")[0];

  // Fetch user by token
  const tokenString = Cookies.get("userJWT");
  let token;
  if (tokenString) {
    try {
      token = jwtDecode(tokenString);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  // Debugging date formats
  console.log("currentDate:", currentDate);

  mcqExamData?.forEach((item) => {
    console.log("select_date:", item.select_date);
  });

  // Ensure date strings and times are compared correctly
  const todayTest = mcqExamData?.filter((item) => {
    return item.select_date >= currentDate;
  });

  // Debugging logs
  console.log("Filtered todayTest:", todayTest);

  // Filter student class according test
  let classTest;
  if (token) {
    classTest = todayTest?.filter(
      (item) => item?.select_class == token?.standard
    );
  }

  //   console.log("token", token);
  console.log("token?.standard", token);
  // console.log("mcqData", mcqData);
  console.log("todayTest", todayTest);
  console.log("classTest", classTest);

  return (
    <>
      <Navbar />
      <Box sx={{ mt: "70px" }}>
        <BreadCrumbs
          pageName="Student"
          subPageName="Live Test"
          title="MCQ Live Test"
        />
        <Box sx={{ margin: "20px 20px 30px 20px" }} data-aos="zoom-in">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Upcoming Live Test</Typography>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateMCQ}
                className="common-color"
                sx={{ mr: "10px" }}
              >
                MCQ Exam
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateSub}
                className="common-color"
                sx={{ mr: "10px" }}
              >
                Subjective Exam
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleNavigateMega}
                className="common-color"
              >
                Mega Exam
              </Button>
            </Box>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <DashboardScreen classTest={classTest} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExamDashboard;
