import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { Box, Container, Typography } from '@mui/material';
import { useState } from 'react';
import  { MonthlyDropdown } from './ParentDropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(date, subject, chapter, marks, marksheet,answers) {
  return { date, subject, chapter, marks, marksheet,answers };
}

const rows = [
  createData('JANUARY', 'Maths', 'Integration', 24, 'view','view'),
  createData('FEBRUARY', 'Maths', 'Integration', 24, 'view','view'),
  createData('MARCH', 'Maths', 'Integration', 24, 'view','view'),
  
  
];

export default function Reports() {

    const [filtereReports,setFilteredReports]=useState('')
    const navigate=useNavigate();
    const {selectedStudent}=useSelector((store)=>store.studentReducer)

    console.log('selectedStudent from reports', selectedStudent)

  const handleViewReport = (id) => {
    navigate(`/parents/reports/${id}`);
  };

  return (
    <div className='slate'>
    <Navbar></Navbar>

    <Container sx={{mt:10}}>
      <Box display='flex' justifyContent='space-between' justifyItems='center' my={4}>
      <Typography variant="h4" component="h2" color='black'>
  Reports - Monthly
</Typography>
<MonthlyDropdown setFilteredReports={setFilteredReports}/>
      </Box>
   
    <TableContainer component={Paper} sx={{ overflowX: 'auto'}}>
      <Table >
        <TableHead >
          <TableRow>
          <StyledTableCell>Month  </StyledTableCell>
       
            <StyledTableCell align="center">View</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.date}>
              <StyledTableCell>{row.date}</StyledTableCell>
             
              <StyledTableCell align="center"><button className="badge badge-success"  onClick={() => handleViewReport(1)}>View Report</button></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
}
