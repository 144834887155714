import React from 'react';
import youtubeIcon from '../images/ic_youtube.svg';
import instaIcon from '../images/ic_insta.svg';
import fbIcon from '../images/ic_fb.svg';
import linkedinIcon from '../images/ic_linkedin.svg';
import { useNavigate } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  const history = useNavigate();
  return (
    <div className='Footer'>
      <div className='links-section'>
        <div className='links-vertical-div'>
          <p className='links-title'>ABOUT</p>
          <p className='links-text' onClick={() => history('/about')}>
            About us
          </p>
          <p className='links-text' onClick={() => history('/story')}>
            Our story
          </p>
          <p className='links-text' onClick={() => history('/vision')}>
            Vision
          </p>
        </div>
        <div className='links-vertical-div'>
          <p className='links-title'>ACADEMICS</p>
          <p className='links-text'>CBSE</p>
          <p className='links-text'>Kerala board</p>
          <p className='links-text'>Resources</p>
          <p className='links-text'>Webinar</p>
        </div>
        <div className='links-vertical-div'>
          <p className='links-title'>COURSES</p>
          <p className='links-title' onClick={() => history('/events')}>
            EVENTS
          </p>
          <p className='links-title'>FAQ</p>
        </div>
      </div>

      <div className='set-rowWise'>
        <div>
          <div className='links-vertical-div pp'>
            <p className='links-title soc'>SOCIAL MEDIA</p>
            <div className='social-media-icons-container'>
              <img
                src={linkedinIcon}
                alt='linkedin-icon'
                className='social-media-icon'
              />
              <img src={fbIcon} alt='fb-icon' className='social-media-icon' />
              <img
                src={instaIcon}
                alt='insta-icon'
                className='social-media-icon'
              />
              <img
                src={youtubeIcon}
                alt='youtube-icon'
                className='social-media-icon'
              />
            </div>
          </div>

          <div className='terms-container'>
            <span
              onClick={() => history('/privacy-policy')}
              style={{ cursor: 'pointer' }}
            >
              Privacy Policy
            </span>
            <span
              onClick={() => history('/t&c')}
              style={{ cursor: 'pointer' }}
            >
              Terms & Conditions
            </span>
          </div>
        </div>
        <div className='terms-container display-columnwise'>
          <span className='contact-title'>Contact Us</span>
          <span className='spanColor'>enroutingcareers@gmail.com</span>
          <span className='spanColor'>+91 - 8076491728</span>
        </div>
      </div>

      <footer className='footer-text'>
        © 2021 Enrouting careers. All rights reserved.
      </footer>
    </div>
  );
};

export default Footer;
