import React from "react";
import "../styles/LivePlans.css";
import { Form } from "react-bootstrap";
import { useState } from "react";
import Box from "@mui/joy/Box";

import Button from "@mui/joy/Button";

import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import { Switch } from "@mui/material";


import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


export default function LivePlans({ closeModal }) {
  const [isChecked, setIsChecked] = useState(false);

  const monthlyPrices=[300,550,700,800]
  const yearlyPrices=[2400,4400,5600,6400]

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="mx-5 mt-4">
      <div className="row justify-content-end justify-items-center ">
        <button
          type="btn-2"
          className="close "
          onClick={closeModal}
          aria-label="Close"
        > X </button>
         
         <Form>
      <div className="d-flex justify-content-center align-items- mb-5">
       {/* <h5 className={`mx-3 ${!isChecked ? "text-muted" : "text-dark"}`}> Monthly</h5> */}
       <Typography level="h2" color={isChecked ? "neutral" : "black"} >Annually</Typography>
       <Switch
       checked={isChecked}
       onChange={handleSwitchChange}
 
/>
        {/* <Form.Check
          type="switch"
          id="monthly-annually-switch"
          label=""
          checked={isChecked}
          onChange={handleSwitchChange}
        /> */}
        {/* <h5 className={` ${isChecked ? "text-muted" : "text-dark"}`}>Annually</h5> */}

        <Typography level="h2" color={!isChecked ? "neutral" : "black"}>Monthly</Typography>
      </div>
    </Form>


      </div>
      {/* <div className="row ">
        <div className="col-sm-12 col-md-3 mb-4" style={{ width: "18rem" }}>
          <div className="card middle">
            <div className="card-header ">
              <span className="badge">MOST POPULAR</span>
            </div>
            <div className="card-body textarea">
              <p className="text-middle">Get 1 subject @ {isChecked? monthlyPrices[0] :yearlyPrices[0]}/-</p>
              <h5 className="card-title-middle">₹ {isChecked ?  (monthlyPrices[0] / 30).toFixed(2) + "/day" : (yearlyPrices[0] / 30).toFixed(2) + "/month"}</h5>
              <h5> Get unlimited access</h5>
              <h5> Get unlimited access</h5>

              <button type="button" className="btn-1">
                Buy Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-4" style={{ width: "18rem" }}>
          <div className="card middle">
            <div className="card-header ">
              <span className="badge">MOST POPULAR</span>
            </div>
            <div className="card-body textarea">
              <p className="text-middle">Get 2 subjects @ {isChecked? monthlyPrices[1] :yearlyPrices[1]}/-</p>
              <h5 className="card-title-middle">₹ {isChecked ?  (monthlyPrices[1] / 30).toFixed(2) + "/day" : (yearlyPrices[1] / 12).toFixed(2) + "/month"}</h5>
              <h5> Get unlimited access</h5>
              <h5> Get unlimited access</h5>

              <button type="button" className="btn-1">
                Buy Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-4" style={{ width: "18rem" }}>
          <div className="card middle">
            <div className="card-header ">
              <span className="badge">MOST POPULAR</span>
            </div>
            <div className="card-body textarea">
              <p className="text-middle">Get 3 subjects @{isChecked? monthlyPrices[2] :yearlyPrices[2]}/-</p>
              <h5 className="card-title-middle">₹ {isChecked ?  (monthlyPrices[2] / 30).toFixed(2) + "/day" : (yearlyPrices[2] / 12).toFixed(2) + "/month"}</h5>
              <h5> Get unlimited access</h5>
              <h5> Get unlimited access</h5>

              <button type="button" className="btn-1">
                Buy Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-4" style={{ width: "18rem" }}>
          <div className="card middle">
            <div className="card-header ">
              <span className="badge">MOST POPULAR</span>
            </div>
            <div className="card-body textarea">
              <p className="text-middle">Get 4 subjects @ {isChecked? monthlyPrices[3] :yearlyPrices[3]}/-</p>
              <h5 className="card-title-middle"> ₹ {isChecked ?  (monthlyPrices[3] / 30).toFixed(2) + "/day" : (yearlyPrices[3] / 12).toFixed(2) + "/month"}</h5>
              <h5> Get unlimited access</h5>
              <h5> Get unlimited access</h5>

              <button type="button" className="btn-1">
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div> */}





<div className="mb-5">

    <Box
      sx={{
        width: '100%',
        display: 'flex',

        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 2,
      }}
    >
      <Card size="md" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
          BASIC
        </Chip>
        <Typography level="h2">Get 1 subject </Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Recorded Lectures
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Live Sessions
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Checking Account
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹  {isChecked? monthlyPrices[0] :yearlyPrices[0]}
            <Typography fontSize="sm" textColor="text.tertiary">
          {  isChecked ?"/ month":"/ year"}
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
      </Card>

      <Card size="md" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
          BASIC
        </Chip>
        <Typography level="h2">Get 2 subjects @<br/>₹ {isChecked? monthlyPrices[1] :yearlyPrices[1]}</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Virtual Credit Cards
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Financial Analytics
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Checking Account
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
        <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹  {isChecked? monthlyPrices[1] :yearlyPrices[1]}
            <Typography fontSize="sm" textColor="text.tertiary">
          {  isChecked ?"/ month":"/ year"}
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
      </Card>

      <Card size="md" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
          BASIC
        </Chip>
        <Typography level="h2">Get 3 subjects @<br/>₹ {isChecked? monthlyPrices[2] :yearlyPrices[2]}</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Virtual Credit Cards
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Financial Analytics
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Checking Account
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
        <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹  {isChecked? monthlyPrices[2] :yearlyPrices[2]}
            <Typography fontSize="sm" textColor="text.tertiary">
          {  isChecked ?"/ month":"/ year"}
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
      </Card>




      
      <Card
        size="lg"
        variant="solid"
        color="neutral"
        invertedColors
        sx={{
          background: "linear-gradient(to right, #fb7b49, #edaf38)",
        }}
      >
        <Chip size="sm" variant="outlined" color="red">
          MOST POPULAR
        </Chip>
        <Typography level="h2" color="white">Get all subjects </Typography>
        <Divider inset="none" />
        <List
          size="sm"
          
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            mx: 'calc(-1 * var(--ListItem-paddingX))',
            color:'white'
          }}
        >
          <ListItem color="white" >
            <ListItemDecorator >
              <Check />
            </ListItemDecorator>
            Unlimited Access
          </ListItem>
          <ListItem color="solid">
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Live Classes
          </ListItem>
          <ListItem color="white" >
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Recorded Lectures
          </ListItem>
          <ListItem color="white">
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            API Integration
          </ListItem>
          <ListItem color="white">
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Cancel Anytime
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
        <Typography level="title-lg" sx={{ mr: 'auto' }} color="white">
          ₹  {isChecked? monthlyPrices[3] :yearlyPrices[3]}
            <Typography fontSize="sm" textColor="text.grey">
          {  isChecked ?"/ month":"/ year"}
            </Typography>
          </Typography>
        
          <Button variant="outlined" endDecorator={<KeyboardArrowRight />} color='neutral'>
              Start now
            </Button>
        </CardActions>
      </Card>
    </Box>
    </div>
    </div>



  );
}
