import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Menu, MenuItem } from '@mui/material';
import ParentDropdown from '../ParentDropdown';

export default function Card0() {


    const settings = {
        width: 340,
        height: 200,
        value: 60 ,
      };
  return (
    <Card sx={{ maxWidth: 345,height:360 }} >


       <Gauge 
      {...settings}
      cornerRadius="50%"
      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 55,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: '#52b202',
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.text.disabled,
        },

      })}
    />
      <CardContent sx={{py:5}}>
        <Typography gutterBottom variant="h5" component="div">
          Total Course Finished
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <ParentDropdown/>
        </Typography>
      </CardContent>
     
      <CardActions>
      
      <Menu ><MenuItem>ALL</MenuItem></Menu>
      </CardActions>
    </Card>
  );
}