import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { screenSize } from '../util/screenSize';
import '../styles/ProfilePage.css';
import { Button, ProgressBar } from 'react-bootstrap';
import nextIcon from '../images/ic_arrow_right.svg';
import MyProgress from './MyProgress';
import { getAllCourses, getUserCourses } from '../redux/actions/courseActions';

const ProgressPage = () => {
  const [openMyProgressModel, setOpenMyProgressModel] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userData } = useSelector((store) => store.userReducer);
  const { allCourses, userCourses } = useSelector((store) => store.courseReducer);

  var screen = window.innerWidth;

  const handleClose = () => {
    setOpenMyProgressModel(false);
    setSelectedCourse(null);
    setSelectedSubject(null);
  };

  useEffect(() => {
    if (allCourses.length === 0) {
      dispatch(getAllCourses());
    }
  }, [dispatch, allCourses.length]);

  const handleViewProgress = (course, subject) => {
    setSelectedCourse(course);
    setSelectedSubject(subject);
    setOpenMyProgressModel(true);
  };

  return (
    <div className="ProfilePage">
      <Navbar activeMenuItem={"profile"} />
      {screen > screenSize ? (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>My Progress</h1>
          </div>
          <div className="contact-details-div">
            <span className="profile-name">Joined on</span>
            <span>12/04/2021</span>
          </div>
        </div>
      ) : (
        <div className="ProfilePage-title-container">
          <div className="ProfilePage-title-row">
            <h1>
              {userData?.firstname} {userData?.lastname}
            </h1>
            <div className="contact-details-div">
              <span className="profile-name">{userData?.phone}</span>
              <span>{userData?.email}</span>
            </div>
          </div>
        </div>
      )}

      {openMyProgressModel && (
        <div>
          <MyProgress
            handleClose={handleClose}
            course={selectedCourse}
            subjectName={selectedSubject}
          />
        </div>
      )}
      <div className="Profile-card-container">
        {allCourses.map((item, index) => (
          <div
            className={`Profile-card ${index === 0 ? 'first-card' : ''}`}
            key={index}
          >
            <h3>{item.subject}</h3>
            <div className="d-flex align-items-center">
              <span className="ms-3">0%</span>
              <ProgressBar
                variant="warning"
                label={`${45}%`}
                animated
                now={45}
                className="flex-grow-1 mx-4"
              />
              <span className="me-3">100%</span>
            </div>

            <div className="mt-4 d-flex justify-content-end">
              <button
                className="viewProgress me-4"
                onClick={() => handleViewProgress(item?.chapters, item.subject)}
              >
                View{" "}
                <img
                  src={nextIcon}
                  alt="forward-icon"
                  className="forward-icon"
                  width={16}
                />
              </button>
              <button className="ttton">Finish Module</button>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ProgressPage;
