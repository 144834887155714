import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../images/ec_logo_nobg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { teacher_logout } from "../../redux/actions/teacherActions";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import { Badge } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { setSelectedStudent } from '../../redux/actions/getStudentActions'
const drawerWidth = 240;

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElExams, setAnchorElExams] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  
  const {parentsData,isAuthenticated}=useSelector((store)=>store.parentReducer)

  const  {selectedStudent}= useSelector((store)=>store.studentReducer)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 // console.log('parentsData', parentsData)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };
  const handleOpenExamsMenu = (event) => {
    setAnchorElExams(event.currentTarget);
  };
  const handleCloseExamsMenu = () => {
    setAnchorElExams(null);
  };


  const handleLogout = () => {
    dispatch(teacher_logout(navigate));
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {["Dashboard", "Rankings", "Exams"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
     
    </div>
  );


  const handleStudentSelect = (student) => {
    dispatch(setSelectedStudent(student));
    navigate(`/parents/${student.id}/dashboard`);
  };


  

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#383838" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              display: { xs: "none", md: "flex" },
              maxHeight: { xs: 65, md: 65 },
              maxWidth: { xs: 65, md: 65 },
              mx: 5,
              cursor: "pointer",
            }}
            alt="The company logo"
            src={logo}
            onClick={() => handleNavigate(`/parents/${selectedStudent?.id}/dashboard`)}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none",}  }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Enrouting Careers
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate(`/parents/${selectedStudent?.id}/dashboard`)}
            >
              Dashboard
            </Button>
            <Button sx={{ my: 2, color: "white", display: "block" }}
            onClick={() => handleNavigate("/parents/ranking")}>
              Rankings
            </Button>

            <Button sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/parents/liveclass")}>
              Live Class
              <Badge
                color="secondary"
                sx={{ px: 0.8 }}
                badgeContent={5}
                max={10}
              ></Badge>
            </Button>
            <Button  sx={{ my: 2, color: "white", display: "block" }} onClick={handleOpenExamsMenu}>
        
               
                  EXAMS
                  <ExpandMoreIcon />
                  
              </Button>
              <Button sx={{ my: 2, color: "white", display: "block" }}
              onClick={() => handleNavigate("/parents/reports")}>
              Reports
              <Badge
                color="secondary"
                sx={{ px: 0.8 }}
              
              ></Badge>
            </Button>
          </Box>
          <Menu
            id="menu-exams"
            anchorEl={anchorElExams}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElExams)}
            onClose={handleCloseExamsMenu}
          >
            <MenuItem onClick={() => handleNavigate("/parents/mcq")}>
              MCQ Exam
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/parents/subjective")}>
              Subjective Exam
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/parents/mega")}>
              Mega Exam
            </MenuItem>
          </Menu>
          
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", lg:'none' },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              
            }}
            
          >
            {drawer}
          </Drawer>
{/* {
  isAuthenticated&&

   <Box sx={{ flexGrow: 0 }}>{
    parentsData?students?.map((item,index)=>(
      <IconButton  sx={{ p: 0 }} key={index}>
      <Avatar>{item?.firstname}</Avatar>
    </IconButton>
    ))
   }
   
   </Box>
} */}
<Box>


<Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{parentsData?.firstname}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        {
          parentsData?.students?.map((item,index)=>
          
            <MenuItem  key={index}  onClick={() => handleStudentSelect(item)}>
            <PermIdentityIcon /> 
            {item.firstname}
          </MenuItem>
          
          )
        }
        <Divider />

        
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>










          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{parentsData?.firstname}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             
              

                {  parentsData?.students?.map((item,index)=>
              <MenuItem key={index}>
                
                <Typography textAlign="center" >{item?.firstname}</Typography>

            
                </MenuItem>
                ) 

                }
             
      
              
               
               
              <MenuItem onClick={handleLogout}>

      
           
          
                <Typography textAlign="center">LOG OUT</Typography>
              </MenuItem>
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
