// import {legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
// import {thunk} from 'redux-thunk';

// import reducers from './reducers';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

// export default store;

import {  applyMiddleware, compose } from 'redux';
import { legacy_createStore as createStore } from 'redux'
import {thunk} from 'redux-thunk';
import reducers from './reducers';
import { configureStore } from '@reduxjs/toolkit';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;

