import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import CoursePage from "./components/CoursePage";
import WorkshopPage from "./components/WorkshopPage";
import EventsPage from "./components/EventsPage";
import ProfilePage from "./components/ProfilePage";
import EditProfilePage from "./components/EditProfilePage";
import SubscriptionPage from "./components/SubscriptionPage";
import PurchasesPage from "./components/PurchasesPage";
import BlogsPage from "./components/BlogsPage";
import AboutUsPage from "./components/AboutUsPage";
import HelpPage from "./components/HelpPage";
import MyEventsPage from "./components/MyEventsPage";
import MyWorkshopPage from "./components/MyWorkshopsPage";
import ErrorPage from "./components/ErrorPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import OurVisionPage from "./components/OurVisionPage";
import OurStoryPage from "./components/OurStoryPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicy";
import TeacherHome from "./components/TeacherHome";
import Room from "./components/Teacher/Room";
import Live from "./components/Live";
import "./index.css";
import Lobby from "./components/Lobby";
import LivePlans from "./components/LivePlans";
import RequestLectures from "./components/RequestLectures";
import TestRoutes from "./routes/Teacher/AllTest/TestRoutes";
import StudentTestRoutes from "./routes/Student/AllTest/StudentTestRoutes";
import TestScreen from "./pages/Students/ViewTest/MCQ_TYPE/TestScreen";
import ExamDashboard from "./pages/Students/ViewTest/ExamDashboard/ExamDashboard";
import ParentRoutes from "./routes/Parents/ParentRoutes";
import ProgressPage from "./components/ProgressPage";
import MyProgress from "./components/MyProgress";
import Rewards from "./components/Rewards";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      {/* <Router history={history}> */}
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/course" element={<CoursePage />} />
        <Route exact path="/workshop" element={<WorkshopPage />} />
        <Route exact path="/events" element={<EventsPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/progress" element={<ProgressPage />} />

        <Route exact path="/profile/edit" element={<EditProfilePage />} />
        <Route exact path="/subscription" element={<SubscriptionPage />} />
        <Route exact path="/purchases" element={<PurchasesPage />} />
        <Route exact path="/notes" element={<PurchasesPage />} />
        <Route exact path="/blogs" element={<BlogsPage />} />
        <Route exact path="/about" element={<AboutUsPage />} />
        <Route exact path="/vision" element={<OurVisionPage />} />
        <Route exact path="/story" element={<OurStoryPage />} />
        <Route exact path="/help" element={<HelpPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/t&c" element={<TermsAndConditionsPage />} />
        <Route exact path="/myEvents" element={<MyEventsPage />} />
        <Route exact path="/myWorkshops" element={<MyWorkshopPage />} />
        <Route
          exact
          path="/forgot-password/:token"
          element={<ResetPasswordPage />}
        />
        <Route exact path="*" element={<ErrorPage />} />
        <Route exact path="/myProgress" element={<MyProgress/>} />
        <Route exact path="/myRewards" element={<Rewards/>} />
      
        <Route exact path="/live" element={<Live />} />
        <Route exact path="/exams" element={<ExamDashboard />} />
        <Route exact path="live/lobby/:id" element={<Lobby />} />
        <Route exact path="/price" element={<LivePlans />} />

        <Route exact path="/teacher-home" element={<TeacherHome />} />
        <Route exact path="/teacher-home/room/:roomd" element={<Room />} />

        <Route exact path="/live-requests" element={<RequestLectures />} />

        {/* import teacher all test */}
        <Route exact path="/test/*" element={<TestRoutes />} />

        {/* import student all test */}
        <Route exact path="/student-test*" element={<StudentTestRoutes />} />

        <Route exact path="/parents*" element={<ParentRoutes />} />
      </Routes>
      {/* </Router> */}
    </>
  );
};

export default App;
