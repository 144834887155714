
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import courseReducer from './courseReducer';
import boardReducer from './boardReducer';
import standardReducer from './standardReducer';
import testimonialReducer from './testimonialReducer';
import eventReducer from './eventReducer';
import workshopReducer from './workshopReducer';
import blogReducer from './blogReducer';
import meetingReducer from './meetingReducer';
import teacherReducer from './teacherReducer';
import requestReducer from './requestReducer';
import dropdownReducer from './dropdownReducer'
import parentReducer from './parentReducer';
import studentReducer from './studentReducer';


export default combineReducers({
  userReducer,
  courseReducer,
  boardReducer,
  standardReducer,
  testimonialReducer,
  eventReducer,
  workshopReducer,
  blogReducer,
  meetingReducer,
  teacherReducer,
  requestReducer,
  dropdownReducer,
  parentReducer,
  studentReducer


});
