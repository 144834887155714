import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { Download } from '@mui/icons-material';
import { red } from '@mui/material/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(subject, chapter, marks, grade) {
  return { subject, chapter, marks, grade };
}

const rows = [
  createData('Maths', 'Integration', 24, 'A'),
  createData('Science', 'Physics', 18, 'B'),
  createData('English', 'Grammar', 20, 'A'),
  createData('History', 'WWII', 22, 'B'),
  createData('Geography', 'Maps', 19, 'B'),
];

export default function ViewReport() {

  const handleDownload = () => {
    // Add your download functionality here
    alert("Download report functionality to be implemented.");
  };

  return (
    <div className='slate'>
      <Navbar />
      <Container sx={{ mt: 10 }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' my={4}>
          <Typography variant="h4" component="h2" color='black'>
            Monthly Report - JULY 2024
          </Typography>
          <Button variant="contained" startIcon={<Download />} onClick={handleDownload}>
            DOWNLOAD
          </Button>
        </Box>

        <Grid container spacing={2} color='red'>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Student Name: John Doe</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Standard: 10th Grade</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">School: XYZ High School</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Live Classes Attended: 15</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Live Classes Missed: 3</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Tests Taken: 5</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Tests Missed: 1</Typography>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Chapter</StyledTableCell>
                <StyledTableCell align="right">Marks</StyledTableCell>
                <StyledTableCell align="right">Grade</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.subject}</StyledTableCell>
                  <StyledTableCell>{row.chapter}</StyledTableCell>
                  <StyledTableCell align="right">{row.marks}</StyledTableCell>
                  <StyledTableCell align="right">{row.grade}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
