import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import SubscriptionCard from './SubscriptionCard';
import TransactionCard from './TransactionCard';
import EventTransactionCard from './EventTransactionCard';
import '../styles/PurchasesPage.css';
import { getUserEvents } from '../redux/actions/eventActions';
import { getUserWorkshops } from '../redux/actions/workshopActions';
import { getUserCourses } from '../redux/actions/courseActions';
import WorkshopTransactionCard from './WorkShopTransactionCard';
import Invoice from './Invoice';
import { jsPDF } from 'jspdf';
import ReactDOMServer from 'react-dom/server';

const PurchasesPage = () => {
  const dispatch = useDispatch();
  const [activeOption, setActiveOption] = useState('subscriptions');
  const [expandView, setExpandView] = useState([]);
  const [boolVal, setBoolval] = useState(false);
  const location = useLocation();

  const { userCourses } = useSelector((store) => store.courseReducer);
  const { userEvents } = useSelector((store) => store.eventReducer);
  const { userWorkshops } = useSelector((store) => store.workshopReducer);
  const { id, firstname, lastname, email, phone, address, state } = useSelector(
    (store) => store.userReducer?.userData
  );

  console.log("id",id)

  useEffect(() => {
    if (!boolVal) {
      dispatch(getUserCourses());
      dispatch(getUserEvents());
      dispatch(getUserWorkshops());
      setBoolval(true);
    }
  }, [dispatch, boolVal]);

  const handleViewClick = (sub) => {
    var index = expandView.indexOf(sub);
    let newArr = [];
    if (index === -1) {
      newArr = [...expandView, sub];
    } else {
      newArr = [...expandView];
      newArr.splice(index, 1);
    }
    setExpandView(newArr);
  };

  const handleDownloadInvoice = (type, data) => {
    // const inNumber = `0000560/${new Date(
    //   data.UserEvents[data.UserEvents.length - 1].purchasedOn
    // ).getDate()}${new Date().getMonth().toLocaleString('en-US', {
    //   minimumIntegerDigits: 2,
    //   useGrouping: false,
    // })}${new Date().getFullYear()}/${id}`;
    var billData = {
      userName: firstname + ' ' + lastname,
      address: address,
      state: state,
      phone: phone,
      email: email,
    };
    if (type === 'event') {
      billData = {
        ...billData,
        invoiceNumber: `0000560/${new Date(
          data.UserEvents[data.UserEvents.length - 1].purchasedOn
        )
          .getDate()
          .toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}${(
          new Date(
            data.UserEvents[data.UserEvents.length - 1].purchasedOn
          ).getMonth() + 1
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}${new Date(
          data.UserEvents[data.UserEvents.length - 1].purchasedOn
        ).getFullYear()}/${id}/${data.id}`,
        issueData: new Date(
          data.UserEvents[data.UserEvents.length - 1].purchasedOn
        ),
        description: `Event - ${data.topic} ${
          data.UserEvents[data.UserEvents.length - 1].isSolo === true
            ? '(solo purchase)'
            : '(group purchase)'
        }`,
        amount:
          data.UserEvents[data.UserEvents.length - 1].isSolo === true
            ? data.soloPrice
            : data.groupPrice,
      };
    }
    if (type === 'course') {
      billData = {
        ...billData,
        invoiceNumber: `0000560/${new Date(
          data.UserCourses[data.UserCourses.length - 1].purchasedOn
        )
          .getDate()
          .toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}${(
          new Date(
            data.UserCourses[data.UserCourses.length - 1].purchasedOn
          ).getMonth() + 1
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}${new Date(
          data.UserCourses[data.UserCourses.length - 1].purchasedOn
        ).getFullYear()}/${id}/${data.id}`,
        issueData: new Date(),
        description: `Course - (${data.subject}, ${data.class}th standard ${data.board})`,
        amount: 1000,
      };
    }

    if (type === 'workshop') {
      billData = {
        ...billData,
        invoiceNumber: `0000560/${new Date(
          data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
        )
          .getDate()
          .toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}${(
          new Date(
            data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
          ).getMonth() + 1
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}${new Date(
          data.UserWorkshops[data.UserWorkshops.length - 1].purchasedOn
        ).getFullYear()}/${id}/${data.id}`,
        issueData: new Date(),
        description: `Workshop - Topic: (${data.topic})`,
        amount: data.price,
      };
    }

    var doc = new jsPDF('p', 'pt', 'a4');
    doc.html(
      ReactDOMServer.renderToStaticMarkup(<Invoice billData={billData} />),
      {
        callback: function (pdf) {
          var pageCount = doc.internal.getNumberOfPages();
          pdf.deletePage(pageCount);
          pdf.save('invoice.pdf');
        },
      }
    );
  };


  
  return (
    <div className='PurchasesPage'>
      <Navbar activeMenuItem={'profile'} />
      <div className='PurchasesPage-title-container'>
        <h1>{location.state === 'purchases' ? 'My purchases' : 'My notes'}</h1>

      {/* <h1>{location?.state === 'purchases' ? 'My purchases' : 'My notes'}  </h1> */}
        <div className='PurchasesPage-options-container'>
          {location.state === 'purchases' ? (
            <Fragment>
              <span
                onClick={() => setActiveOption('subscriptions')}
                className={activeOption === 'subscriptions' && 'active-option'}
              >
                Manage Subscriptions
              </span>
              <span
                onClick={() => setActiveOption('transactions')}
                className={activeOption === 'transactions' && 'active-option'}
              >
                Transactions
              </span>
            </Fragment>
          ) : (
            <span>All notes you have subscribes are here.</span>
          )}
        </div>
      </div>
      {activeOption === 'transactions' ? (
        <div className='transactions-card-container'>
          <div className='transactions-card-container-title-row'>
            <p className='first-title'>My purchases</p>
            <p className='middle-title'>Price</p>
            <p className='last-title'>Purchase Date</p>
          </div>
          {userCourses?.map((course) => (
            <TransactionCard
              purchaseDetails={course.UserCourses}
              standard={course.class}
              subject={course.subject}
              yearlyPrice={course.yearlyPrice}
              halfyearlyPrice={course.halfyearlyPrice}
              quarterlyPrice={course.quarterlyPrice}
              date={course.createdAt}
              handleDownloadInvoice={handleDownloadInvoice}
            />
          ))}
          <EventTransactionCard
            userEvents={userEvents}
            handleDownloadInvoice={handleDownloadInvoice}
          />
          <WorkshopTransactionCard
            userWorkshops={userWorkshops}
            handleDownloadInvoice={handleDownloadInvoice}
          />
        </div>
      ) : (
        <div className='subscriptions-card-content'>
          {userCourses?.map((course, index) => (
            <div className='subscriptions-card-container'>
              <p className='subscriptions-card-title'>
                Class {course.class}th : {course.subject} notes
              </p>
              {course?.chapters
                ?.sort((a, b) =>
                  a.number > b.number ? 1 : b.number > a.number ? -1 : 0
                )
                .map((chapter, idx) =>
                  idx <= 1 ? (
                    <SubscriptionCard chapter={chapter} />
                  ) : (
                    expandView.includes(course.id) && (
                      <SubscriptionCard chapter={chapter} />
                    )
                  )
                )}
              <div className='view-toggle'>
                <span onClick={() => handleViewClick(course.id)}>
                  {expandView.includes(course.id) ? 'View less' : 'View more'}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PurchasesPage;
