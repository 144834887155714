// import React, { useEffect, useState } from 'react';
// import closeIcon from '../images/ic_close.svg';
// import prevLightIcon from '../images/ic_arrow_left_white.svg';
// import prevDarkIcon from '../images/ic_arrow_left_gray.svg';
// import nextLightIcon from '../images/ic_arrow_right_white.svg';
// import nextDarkIcon from '../images/ic_arrow_right_gray.svg';
// import escLightIcon from '../images/ic_esc_white.svg';
// import escDarkIcon from '../images/ic_esc_gray.svg';
// import '../styles/ReadWatchModal.css';

// const ReadWatchModal = (props) => {
//   const { type, handleClose, chapter } = props;
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   useEffect(() => {
//     const noContext = document.getElementById("noContextMenu");
//     noContext.addEventListener('contextmenu', e => {
//       e.preventDefault();
//     });
//   });

//   return (
//     <div className="ReadWatchModal">
//       <div className={`ReadWatchModal-content ${isDarkMode && 'dark-mode'}`}>
//         <span onClick={() => handleClose()}>
//           <img src={closeIcon} alt="close-icon" className="ReadWatchModal-close-icon" />
//         </span>
//         <div className="ReadWatchModal-title-container">
//           <h1>{chapter.name}</h1>
//         </div>
//         <p className="ReadWatchModal-notes-text">
//           {type === 'notes' ? 'Notes' : 'Video lecture'}
//         </p>
//         {type === 'notes' ? (
//           <div className={`ReadWatchModal-notes-container ${isDarkMode && 'dark-mode'}`}>
//             <iframe
//               id="noContextMenu"
//               className="notes-frame"
//               src={`${chapter.notes}#toolbar=0&navpanes=0&scrollbar=0`}
//               type="application/pdf"
//               scrolling="auto"
//               frameBorder="0"
//               height="100%"
//               width="100%"
//               title="notes"
//             ></iframe>
//           </div>
//         ) : (
//           <div className={`ReadWatchModal-lecture-container ${isDarkMode && 'dark-mode'}`}>
//             <video
//               id="noContextMenu"
//               controls
//               controlsList="nodownload"
//               className="video-frame"
//             >
//               <source src={chapter.url} />
//             </video>
//           </div>
//         )}
//         <div className="ReadWatchModal-options-container">
//           <div className="ReadWatchModal-toggle-div">
//             <p className={`toggle-switch-label ${isDarkMode && 'dark-mode'}`}>Dark mode</p>
//             <label className="toggle-switch">
//               <input
//                 type="checkbox"
//                 checked={isDarkMode}
//                 onChange={() => setIsDarkMode(!isDarkMode)} />
//               <span className="switch" />
//             </label>
//           </div>
//           <div className={`ReadWatchModal-prev-next-div ${isDarkMode && 'dark-mode'}`}>
//             <div className="prev-chapter-div">
//               <img
//                 src={isDarkMode ? prevLightIcon : prevDarkIcon}
//                 alt="prev-icon"
//                 className="prev-icon"
//               />
//               <span>Previous chapter</span>
//             </div>
//             <div className="next-chapter-div">
//               <span>Next chapter</span>
//               <img
//                 src={isDarkMode ? nextLightIcon : nextDarkIcon}
//                 alt="next-icon"
//                 className="next-icon"
//               />
//             </div>
//           </div>
//           <div className={`ReadWatchModal-close-div ${isDarkMode && 'dark-mode'}`}>
//             <img
//               src={isDarkMode ? escLightIcon : escDarkIcon}
//               alt="esc-icon"
//               className="esc-icon"
//             />
//             <span>Close</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReadWatchModal;




// import React, { useEffect, useState } from 'react';
// import closeIcon from '../images/ic_close.svg';
// import prevLightIcon from '../images/ic_arrow_left_white.svg';
// import prevDarkIcon from '../images/ic_arrow_left_gray.svg';
// import nextLightIcon from '../images/ic_arrow_right_white.svg';
// import nextDarkIcon from '../images/ic_arrow_right_gray.svg';
// import escLightIcon from '../images/ic_esc_white.svg';
// import escDarkIcon from '../images/ic_esc_gray.svg';
// import '../styles/ReadWatchModal.css';

// const ReadWatchModal = ({ type, handleClose, chapter }) => {
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   useEffect(() => {
//     const noContext = document.getElementById('noContextMenu');
//     if (noContext) {
//       const preventContextMenu = (e) => e.preventDefault();
//       noContext.addEventListener('contextmenu', preventContextMenu);
//       return () => {
//         noContext.removeEventListener('contextmenu', preventContextMenu);
//       };
//     }
//   }, []);

//   const handleProgressUpdate = (watchedPercentage) => {
//     console.log(`User has watched ${watchedPercentage}% of the video.`);
//     // Send to backend
//     fetch('/api/video/progress', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ chapterId: chapter.id, watchedPercentage }),
//     });
//   };

//   return (
//     <div className="ReadWatchModal">
//       <div className={`ReadWatchModal-content ${isDarkMode ? 'dark-mode' : ''}`}>
//         <span onClick={handleClose} role="button" tabIndex={0} aria-label="Close">
//           <img src={closeIcon} alt="Close icon" className="ReadWatchModal-close-icon" />
//         </span>
//         <div className="ReadWatchModal-title-container">
//           <h1>{chapter.name}</h1>
//         </div>
//         <p className="ReadWatchModal-notes-text">
//           {type === 'notes' ? 'Notes' : 'Video lecture'}
//         </p>
//         {type === 'notes' ? (
//           <div className={`ReadWatchModal-notes-container ${isDarkMode ? 'dark-mode' : ''}`}>
//             <iframe
//               id="noContextMenu"
//               className="notes-frame"
//               src={`${chapter.notes}#toolbar=0&navpanes=0&scrollbar=0`}
//               type="application/pdf"
//               scrolling="auto"
//               frameBorder="0"
//               height="100%"
//               width="100%"
//               title="Notes"
//               aria-label="Notes content"
//             ></iframe>
//           </div>
//         ) : (
//           <div className={`ReadWatchModal-lecture-container ${isDarkMode ? 'dark-mode' : ''}`}>
//             <video
//               id="noContextMenu"
//               controls
//               controlsList="nodownload"
//               className="video-frame"
//               onTimeUpdate={(e) => {
//                 const { currentTime, duration } = e.target;
//                 if (duration > 0) {
//                   const watchedPercentage = (currentTime / duration) * 100;
//                   handleProgressUpdate(watchedPercentage.toFixed(2));
//                 }
//               }}
//             >
//               <source src={chapter.url} type="video/mp4" />
//             </video>
//           </div>
//         )}
//         <div className="ReadWatchModal-options-container">
//           <div className="ReadWatchModal-toggle-div">
//             <p className={`toggle-switch-label ${isDarkMode ? 'dark-mode' : ''}`}>Dark mode</p>
//             <label className="toggle-switch">
//               <input
//                 type="checkbox"
//                 checked={isDarkMode}
//                 onChange={() => setIsDarkMode(!isDarkMode)}
//               />
//               <span className="switch" />
//             </label>
//           </div>
//           <div className={`ReadWatchModal-prev-next-div ${isDarkMode ? 'dark-mode' : ''}`}>
//             <div className="prev-chapter-div" onClick={() => {/* Handle previous chapter logic */}} role="button" tabIndex={0}>
//               <img
//                 src={isDarkMode ? prevLightIcon : prevDarkIcon}
//                 alt="Previous chapter icon"
//                 className="prev-icon"
//               />
//               <span>Previous chapter</span>
//             </div>
//             <div className="next-chapter-div" onClick={() => {/* Handle next chapter logic */}} role="button" tabIndex={0}>
//               <span>Next chapter</span>
//               <img
//                 src={isDarkMode ? nextLightIcon : nextDarkIcon}
//                 alt="Next chapter icon"
//                 className="next-icon"
//               />
//             </div>
//           </div>
//           <div className={`ReadWatchModal-close-div ${isDarkMode ? 'dark-mode' : ''}`}>
//             <img
//               src={isDarkMode ? escLightIcon : escDarkIcon}
//               alt="Esc icon"
//               className="esc-icon"
//             />
//             <span>Close</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReadWatchModal;



// import React, { useEffect, useState, useRef } from 'react';
// import closeIcon from '../images/ic_close.svg';
// import prevLightIcon from '../images/ic_arrow_left_white.svg';
// import prevDarkIcon from '../images/ic_arrow_left_gray.svg';
// import nextLightIcon from '../images/ic_arrow_right_white.svg';
// import nextDarkIcon from '../images/ic_arrow_right_gray.svg';
// import escLightIcon from '../images/ic_esc_white.svg';
// import escDarkIcon from '../images/ic_esc_gray.svg';
// import '../styles/ReadWatchModal.css';
// import { useSelector } from 'react-redux';
// import config from '../config';


// const ReadWatchModal = ({ type, handleClose, chapter }) => {
//   const [isDarkMode, setIsDarkMode] = useState(false);
//   const [watchedPercentage, setWatchedPercentage] = useState(0);
//   const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
//   const videoRef = useRef(null);
//   const {isAuthenticated,userData}=useSelector((store)=>store.userReducer)


//   useEffect(() => {
//     const noContext = document.getElementById('noContextMenu');
//     if (noContext) {
//       const preventContextMenu = (e) => e.preventDefault();
//       noContext.addEventListener('contextmenu', preventContextMenu);
//       return () => {
//         noContext.removeEventListener('contextmenu', preventContextMenu);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (isAuthenticated) {


//       // Fetch the user's progress from the backend
//       fetch(`/api/video/progress/${chapter.id}`)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.watchedPercentage < 100) {
//             setWatchedPercentage(data.watchedPercentage);
//             if (videoRef.current) {
//               videoRef.current.currentTime =
//                 (data.watchedPercentage / 100) * videoRef.current.duration;
//             }
//           }
//         })
//         .catch((error) => console.error('Error fetching progress:', error));
//     }
//   }, [isAuthenticated, chapter.id]);

//   const handleProgressUpdate = (percentage) => {
//     // If not authenticated or already completed 100%, do not track
//     if (!isAuthenticated || watchedPercentage >= 100) return;
//     setWatchedPercentage(percentage);
//     console.log(`User has watched ${percentage}% of the video.`);

//     const currentTime = Date.now();
//     const fifteenSeconds = 15 *60 * 1000;

//     if (currentTime - lastUpdateTime >= fifteenSeconds) {
//       console.log('Updating progress to backend...');
//       setLastUpdateTime(currentTime);
//     }
 
// console.log('userData', userData.id)
// console.log('chapter',chapter.id)
// console.log('courseID',chapter.courseId)



//     // Send progress to backend
//     fetch(`${config.BASE_URL}/api/user/watch-video`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ chapterId: chapter.id, watchedPercentage: percentage }),
//     });
//   };

//   return (
//     <div className="ReadWatchModal">
//       <div className={`ReadWatchModal-content ${isDarkMode ? 'dark-mode' : ''}`}>
//         <span onClick={handleClose} role="button" tabIndex={0} aria-label="Close">
//           <img src={closeIcon} alt="Close icon" className="ReadWatchModal-close-icon" />
//         </span>
//         <div className="ReadWatchModal-title-container">
//           <h1>{chapter.name}</h1>
//         </div>
//         <p className="ReadWatchModal-notes-text">
//           {type === 'notes' ? 'Notes' : 'Video lecture'}
//         </p>
//         {type === 'notes' ? (
//           <div className={`ReadWatchModal-notes-container ${isDarkMode ? 'dark-mode' : ''}`}>
//             <iframe
//               id="noContextMenu"
//               className="notes-frame"
//               src={`${chapter.notes}#toolbar=0&navpanes=0&scrollbar=0`}
//               type="application/pdf"
//               scrolling="auto"
//               frameBorder="0"
//               height="100%"
//               width="100%"
//               title="Notes"
//               aria-label="Notes content"
//             ></iframe>
//           </div>
//         ) : (
//           <div className={`ReadWatchModal-lecture-container ${isDarkMode ? 'dark-mode' : ''}`}>
//             <video
//               id="noContextMenu"
//               controls
//               controlsList="nodownload"
//               className="video-frame"
//               ref={videoRef}
//               onLoadedMetadata={() => {
//                 if (videoRef.current && watchedPercentage > 0) {
//                   videoRef.current.currentTime =
//                     (watchedPercentage / 100) * videoRef.current.duration;
//                 }
//               }}
//               onTimeUpdate={(e) => {
//                 const { currentTime, duration } = e.target;
//                 if (duration > 0) {
//                   const percentage = (currentTime / duration) * 100;
//                   handleProgressUpdate(percentage.toFixed(2));
//                 }
//               }}
//             >
//               <source src={chapter.url} type="video/mp4" />
//             </video>
//           </div>
//         )}
//         <div className="ReadWatchModal-options-container">
//           <div className="ReadWatchModal-toggle-div">
//             <p className={`toggle-switch-label ${isDarkMode ? 'dark-mode' : ''}`}>Dark mode</p>
//             <label className="toggle-switch">
//               <input
//                 type="checkbox"
//                 checked={isDarkMode}
//                 onChange={() => setIsDarkMode(!isDarkMode)}
//               />
//               <span className="switch" />
//             </label>
//           </div>
//           <div className={`ReadWatchModal-prev-next-div ${isDarkMode ? 'dark-mode' : ''}`}>
//             <div className="prev-chapter-div" onClick={() => {/* Handle previous chapter logic */}} role="button" tabIndex={0}>
//               <img
//                 src={isDarkMode ? prevLightIcon : prevDarkIcon}
//                 alt="Previous chapter icon"
//                 className="prev-icon"
//               />
//               <span>Previous chapter</span>
//             </div>
//             <div className="next-chapter-div" onClick={() => {/* Handle next chapter logic */}} role="button" tabIndex={0}>
//               <span>Next chapter</span>
//               <img
//                 src={isDarkMode ? nextLightIcon : nextDarkIcon}
//                 alt="Next chapter icon"
//                 className="next-icon"
//               />
//             </div>
//           </div>
//           <div className={`ReadWatchModal-close-div ${isDarkMode ? 'dark-mode' : ''}`}>
//             <img
//               src={isDarkMode ? escLightIcon : escDarkIcon}
//               alt="Esc icon"
//               className="esc-icon"
//             />
//             <span>Close</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReadWatchModal;





import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import closeIcon from '../images/ic_close.svg';
import prevLightIcon from '../images/ic_arrow_left_white.svg';
import prevDarkIcon from '../images/ic_arrow_left_gray.svg';
import nextLightIcon from '../images/ic_arrow_right_white.svg';
import nextDarkIcon from '../images/ic_arrow_right_gray.svg';
import escLightIcon from '../images/ic_esc_white.svg';
import escDarkIcon from '../images/ic_esc_gray.svg';
import '../styles/ReadWatchModal.css';
import { useSelector } from 'react-redux';
import config from '../config';

const ReadWatchModal = ({ type, handleClose, chapter }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [watchedPercentage, setWatchedPercentage] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const videoRef = useRef(null);
  const { isAuthenticated, userData } = useSelector((store) => store.userReducer);


console.log(chapter)
  useEffect(() => {
    const noContext = document.getElementById('noContextMenu');
    if (noContext) {
      const preventContextMenu = (e) => e.preventDefault();
      noContext.addEventListener('contextmenu', preventContextMenu);
      return () => {
        noContext.removeEventListener('contextmenu', preventContextMenu);
      };
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // Fetch the user's progress from the backend
      axios.get(`/api/video/progress/${chapter.id}`)
        .then((response) => {
          const data = response.data;
          if (data.watchedPercentage < 100) {
            setWatchedPercentage(data.watchedPercentage);
            if (videoRef.current) {
              videoRef.current.currentTime =
                (data.watchedPercentage / 100) * videoRef.current.duration;
            }
          }
        })
        .catch((error) => console.error('Error fetching progress:', error));
    }
  }, [isAuthenticated, chapter.id]);

  useEffect(() => {
    let intervalId;
    if (isAuthenticated) {
      intervalId = setInterval(() => {
        if (watchedPercentage > 0 && watchedPercentage < 100) {
          console.log('Updating progress to backend...');
          // Send progress to backend
          axios.post(`${config.BASE_URL}/api/user/watch-video`, {
            userId: userData?.id?.toString(),
            subjects: [
              {
                subjectId: chapter?.courseId?.toString(),
                chapters: [
                  {
                    chapterId: chapter?.id?.toString(),
                    percentage: 85,
                  },
                ],
              },
            ],
          })
          .then(() => setLastUpdateTime(Date.now()))
          .catch((error) => console.error('Error updating progress:', error));
        }
      }, 20000); // 10 seconds
    }
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [ watchedPercentage]);

  const handleProgressUpdate = (percentage) => {
    if (!isAuthenticated || watchedPercentage >= 100) return;
    setWatchedPercentage(percentage);
    console.log(`User has watched ${percentage}% of the video.`);
  };

  return (
    <div className="ReadWatchModal">
      <div className={`ReadWatchModal-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <span onClick={handleClose} role="button" tabIndex={0} aria-label="Close">
          <img src={closeIcon} alt="Close icon" className="ReadWatchModal-close-icon" />
        </span>
        <div className="ReadWatchModal-title-container">
          <h1>{chapter.name}</h1>
        </div>
        <p className="ReadWatchModal-notes-text">
          {type === 'notes' ? 'Notes' : 'Video lecture'}
        </p>
        {type === 'notes' ? (
          <div className={`ReadWatchModal-notes-container ${isDarkMode ? 'dark-mode' : ''}`}>
            <iframe
              id="noContextMenu"
              className="notes-frame"
              src={`${chapter?.notes}#toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              scrolling="auto"
              frameBorder="0"
              height="100%"
              width="100%"
              title="Notes"
              aria-label="Notes content"
            ></iframe>
          </div>
        ) : (
          <div className={`ReadWatchModal-lecture-container ${isDarkMode ? 'dark-mode' : ''}`}>
            <video
              id="noContextMenu"
              controls
              controlsList="nodownload"
              className="video-frame"
              ref={videoRef}
              onLoadedMetadata={() => {
                if (videoRef.current && watchedPercentage > 0) {
                  videoRef.current.currentTime =
                    (watchedPercentage / 100) * videoRef.current.duration;
                }
              }}
              onTimeUpdate={(e) => {
                const { currentTime, duration } = e.target;
                if (duration > 0) {
                  const percentage = (currentTime / duration) * 100;
                  handleProgressUpdate(percentage.toFixed(2));
                }
              }}
            >
              <source src={chapter.url} type="video/mp4" />
            </video>
          </div>
        )}
        <div className="ReadWatchModal-options-container">
          <div className="ReadWatchModal-toggle-div">
            <p className={`toggle-switch-label ${isDarkMode ? 'dark-mode' : ''}`}>Dark mode</p>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isDarkMode}
                onChange={() => setIsDarkMode(!isDarkMode)}
              />
              <span className="switch" />
            </label>
          </div>
          <div className={`ReadWatchModal-prev-next-div ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className="prev-chapter-div" onClick={() => {/* Handle previous chapter logic */}} role="button" tabIndex={0}>
              <img
                src={isDarkMode ? prevLightIcon : prevDarkIcon}
                alt="Previous chapter icon"
                className="prev-icon"
              />
              <span>Previous chapter</span>
            </div>
            <div className="next-chapter-div" onClick={() => {/* Handle next chapter logic */}} role="button" tabIndex={0}>
              <span>Next chapter</span>
              <img
                src={isDarkMode ? nextLightIcon : nextDarkIcon}
                alt="Next chapter icon"
                className="next-icon"
              />
            </div>
          </div>
          <div className={`ReadWatchModal-close-div ${isDarkMode ? 'dark-mode' : ''}`}>
            <img
              src={isDarkMode ? escLightIcon : escDarkIcon}
              alt="Esc icon"
              className="esc-icon"
            />
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadWatchModal;
