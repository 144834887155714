

import React,{useEffect} from "react";
import Navbar from "./Navbar";
import Card1 from "./Cards/Card1";

import { Container, Grid } from "@mui/material";
import { Margin } from "@mui/icons-material";
import Footer from "./Footer";
import Card0 from "./Cards/Card0";
import ParentDropdown from "./ParentDropdown";
import Card2 from "./Cards/Card2";

import { useDispatch, useSelector } from "react-redux";
import SubjectiveCard from "./Cards/SubjectiveCard";
import MegaExamCard from "./Cards/MegaExamCard";
import { fetchAllExams } from "../../redux/actions/getStudentActions";
import ParentLiveCards from "./Cards/ParentLiveCards";



export const Dashboard = () => {

const dispatch=useDispatch()
const  {selectedStudent}= useSelector((store)=>store.studentReducer)


useEffect(()=>{
  dispatch(fetchAllExams())
    },[])

  return (
    <div  className="slate">
      <Navbar />
      <Container maxWidth='xl'  >
        <Grid container spacing={4}  sx={{ mt: 10 }}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
         
            {/* <Card0 /> */}
            <Card2/>
            
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
       
          <ParentLiveCards/>
          <SubjectiveCard/>
       
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card1 />
            <MegaExamCard/>
          </Grid>

        </Grid>
      </Container>

      
  
     
    </div>
  );
}

export default Dashboard;
