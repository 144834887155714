import React from 'react';
import moment from 'moment';
import '../styles/Invoice.css';
const Invoice = (props) => {
  const { billData } = props;
  return (
    <div className='invoice-temp-container'>
      <div className='invoice-temp-section-1'>
        <div className='company-details'>
          <h4 style={{ fontSize: '0.8rem' }}>Enrouting Careers Pvt. Ltd.</h4>
          <div className='company-address'>
            <p className='invoice-para'>2nd Floor, Center Point, M.G. Road</p>
            <p className='invoice-para'>Thrissur 680004, Kerala</p>
            <p className='invoice-para'>India</p>
          </div>
          <div className='company-credentials'>
            <p className='invoice-para'>8076491728</p>
            <p className='invoice-para'>enroutingcareers@gmail.com</p>
            <p className='invoice-para'>GST No. - 32AAFCE5000L1Z1</p>
          </div>
        </div>
        <div className='page-title'>
          <h2 style={{ fontSize: '1.2rem' }}>INVOICE</h2>
        </div>
      </div>
      {/*---------------------Section 2----------------*/}
      <div className='invoice-temp-section-2'>
        <div className='user-details'>
          <h5 style={{ fontSize: '0.6rem' }}>Bill to: </h5>
          <div className='user-address'>
            <p className='invoice-para'>{billData.userName}</p>
            <p className='invoice-para'>{billData.address}</p>
            <p className='invoice-para'>{billData.state}</p>
            <p className='invoice-para'>{billData.phone}</p>
            <p className='invoice-para'>{billData.email}</p>
          </div>
        </div>
        <div className='user-invoice-details'>
          <p className='invoice-para'>
            Invoice number: {billData.invoiceNumber}
          </p>
          <p className='invoice-para'>
            Issue date: {moment(billData.issueData).format('DD/MM/YYYY')}
          </p>
        </div>
      </div>
      {/*---------------------Section 3----------------*/}
      <div className='invoice-temp-section-3'>
        <table
          width='80%'
          style={{
            borderCollapse: 'collapse',
            borderBottom: '1px solid #eee',
            margin: 'auto',
            marginTop: '1rem',
          }}
        >
          <thead>
            <tr style={{ width: '100%' }}>
              <th className='column-header invoice-description'>Description</th>
              <th className='column-header invoice-amount '>
                Amount (Excl. GST)
              </th>
              <th className='column-header invoice-gst-rate'>GST Rate</th>
              <th className='column-header invoice-gst-net'>GST Net</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td className='invoice-table-row invoice-description'>
                {billData.description}
              </td>
              <td className='invoice-table-row invoice-amount'>
                {(
                  parseInt(billData.amount) -
                  0.18 * parseInt(billData.amount)
                ).toFixed(3)}
              </td>
              <td className='invoice-table-row invoice-gst-rate'>18%</td>
              <td className='invoice-table-row invoice-gst-net'>
                {(parseInt(billData.amount) * 0.18).toFixed(3)}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table
          width='80%'
          style={{
            padding: '20px',
            margin: 'auto',
            marginBottom: '2rem',
          }}
        >
          <tr>
            <td>
              <table
                width='45%'
                style={{ float: 'right', background: '#eee', padding: '20px' }}
              >
                <tr>
                  <td>
                    <strong
                      style={{
                        fontSize: '0.5rem',
                        padding: '0 10px',
                      }}
                    >
                      Sub-total:
                    </strong>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      fontSize: '0.5rem',
                      padding: '0 10px',
                    }}
                  >
                    {(
                      parseInt(billData.amount) -
                      0.18 * parseInt(billData.amount)
                    ).toFixed(3)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      style={{
                        fontSize: '0.5rem',
                        padding: '0 10px',
                      }}
                    >
                      GST total:
                    </strong>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      fontSize: '0.5rem',
                      padding: '0 10px',
                    }}
                  >
                    {(0.18 * parseInt(billData.amount)).toFixed(3)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      style={{
                        fontSize: '0.5rem',
                        padding: '0 10px',
                      }}
                    >
                      Total amount:
                    </strong>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      fontSize: '0.5rem',
                      padding: '0 10px',
                    }}
                  >
                    {billData.amount}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Invoice;
