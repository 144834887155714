// //Doesnt give pathname error

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import {jwtDecode} from 'jwt-decode'; // Fixed the import
// import Cookies from 'js-cookie';
// import App from './App';
// import store from './redux/store';
// import './index.css';
// import { setUserDetails, logout } from './redux/actions/userActions';
// import { BrowserRouter as Router } from 'react-router-dom'; // Fixed the import

// const jsx = (
//   <React.StrictMode>
//     <Router>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </Router>
//   </React.StrictMode>
// );

// const rootElement = document.getElementById('root');

// if (Cookies.get('userJWT')) {
//   const decoded = jwtDecode(Cookies.get('userJWT'));
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(logout()).then(() => {
//       ReactDOM.render(jsx, rootElement);
//     });
//   } else {
//     store.dispatch(setUserDetails()).then(() => {
//       ReactDOM.render(jsx, rootElement);
//     });
//   }
// } else {
//   ReactDOM.render(jsx, rootElement);
// }

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { jwtDecode } from "jwt-decode"; // Corrected import
import Cookies from "js-cookie";
import App from "./App";
import store from "./redux/store";
import "./index.css";
import { setUserDetails, logout } from "./redux/actions/userActions";
import {
  setTeacherDetails,
  teacher_logout,
} from "./redux/actions/teacherActions";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a QueryClient instance
const queryClient = new QueryClient();

const jsx = (
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            pauseOnHover={false}
            rtl={false}
          />
        </Provider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

if (Cookies.get("userJWT")) {
  const decoded = jwtDecode(Cookies.get("userJWT"));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  } else {
    store.dispatch(setUserDetails()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  }
} else if (Cookies.get("teacherJWT")) {
  const decoded = jwtDecode(Cookies.get("teacherJWT"));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(teacher_logout()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  } else {
    store.dispatch(setTeacherDetails()).then(() => {
      ReactDOM.createRoot(rootElement).render(jsx);
    });
  }
} else {
  ReactDOM.createRoot(rootElement).render(jsx);
}

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
// import {jwtDecode} from 'jwt-decode';
// import Cookies from 'js-cookie';
// import App  from './App';
// import store from './redux/store';
// import './index.css';
// import { Router } from 'react-router-dom';

// import { setUserDetails, logout } from './redux/actions/userActions';

// const jsx = (
//   <React.StrictMode>

//       <Provider store={store}>
//         <App />
//       </Provider>

//   </React.StrictMode>
// );

// const rootElement = ReactDOM.createRoot(document.getElementById('root'));
// const checkUserJWT = async () => {

// if (Cookies.get('userJWT')) {
//   const decoded = jwtDecode(Cookies.get('userJWT'));
//   console.log('Decoded Token:', decoded);
//   const currentTime = Date.now() / 1000;

//   if (decoded.exp < currentTime) {
//    await store.dispatch(logout())

//   } else {
//    await  store.dispatch(setUserDetails())

//   }
// } else {
//   rootElement.render(jsx);
// }
// }
// checkUserJWT();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import {jwtDecode} from 'jwt-decode';
// import Cookies from 'js-cookie';
// import App from './App';
// import store from './redux/store';
// import './index.css';

// import { setUserDetails, logout } from './redux/actions/userActions';

// const jsx = (
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// if (Cookies.get('userJWT')) {
//   const decoded = jwtDecode(Cookies.get('userJWT'));
//   // Check for expired token
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(logout())
//       .then(() => ReactDOM.render(jsx, document.getElementById('root')));
//   } else {
//     store.dispatch(setUserDetails())
//       .then(() => ReactDOM.render(jsx, document.getElementById('root')));
//   }
// } else {
//   ReactDOM.render(jsx, document.getElementById('root'));
// }
