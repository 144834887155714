
import React, { useEffect } from "react";
import image from "../images/img_help.svg";
import "../styles/Cards.css";
import { Link, useNavigate } from "react-router-dom";
import {capitalizeFirstLetter} from '../util/helperFunctions'

import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../redux/actions/meetingActions";
import {
  formatDate,
  getMinuteDifference,
  calculateCountdown,
} from "../functions/date";
import maleTeacher from "../images/front-view-man-classroom.jpg";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
const Cards = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { meetings } = useSelector((store) => store.meetingReducer);
  const {teacherData}=useSelector((store)=>store.teacherReducer)

  const teacherId=teacherData?.id
  console.log('teacherData from cards',teacherData)

  // const token = jwtDecode(Cookies.get("teacherJWT"));

  // const teacherId = token?.id;



  useEffect(() => {
    if (!meetings) {
      // Check if meetings is null or undefined
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]); // Include meetings in the dependency array

  // console.log("meetings", meetings?.data?.meeting);

  // Check if meetings?.data?.meeting exists before reversing
  const meeetingData = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const currentTime = new Date().getTime();
  const activeMeetings = meeetingData.filter(
    (meeting) => new Date(meeting.endTime).getTime() > currentTime
  );
  console.log("meeting data", meeetingData);

  const meetingByTeacher = activeMeetings.filter(
    (item) => item.teacherId == teacherId
  );



  const handleJoin = async (ID) => {
    const meetIdToView = await meetingByTeacher.find((meet) => meet.id === ID);
    navigate(`/teacher-home/room/${ID}`, { state: { meetData: meetIdToView } });
  };

  if (!meetingByTeacher || meetingByTeacher.length === 0) {
    return (
      <div className="custom-card_no_meet mt-3">
        <div className="custom-flex">
          <div className="custom-body">
            <p className="custom-text">No lectures are scheduled</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {meetingByTeacher?.map((item, index) => {
        const differenceMinutes = getMinuteDifference(
          item.startTime,
          item.endTime
        );
        const startTimeMillis = new Date(item?.startTime).getTime();
        const endTimeMillis = new Date(item?.endTime).getTime();
        const { label, disabled, color } = calculateCountdown(
          startTimeMillis,
          endTimeMillis
        );

        return (
          <div key={index} className="custom-card  m-3">
            <div className="custom-flex">
              <div className="custom-image">
                <img src={maleTeacher} className="custom-img" alt="Card" />
              </div>
              <div className="custom-body">
                <div className="custom-date">
                  {" "}
                  {formatDate(item?.date)} | {differenceMinutes}mins{" "}
                </div>
                <h5 className="custom-title">
                  {item?.chapter} | {item?.subject}
                </h5>
                <p className="custom-text">{capitalizeFirstLetter(item?.createdBy)}</p>
                <p className="custom-class">Class {item?.class}</p>
                <span style={{ color }}>{label}</span>


                
                {!disabled && (
                  <Link
                    className={`custom-btn ${color}`}
                    onClick={() => handleJoin(item.id)}
                  >
                    Join Now
                  </Link>
                )}

                {/* {!disabled && (
                    <button className={`custom-btn ${color}`} onClick={() => window.open(item.url, '_blank')}  >
                   JOIN 
                    </button>
                  )} */}


                {/* <Link className="custom-btn" to={ baseUrl +"/teacher-home/room/" +item?.url}>Join Now</Link> */}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Cards;
