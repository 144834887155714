import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

// import '../styles/AboutUsPage.css';
import StudentCard from './StudentCard';
import { useSelector } from 'react-redux';
import DummyCards from './DummyCards';
import ForgotLectures from './ForgotLectures';

const Live = () => {


const {isAuthenticated }=useSelector((store)=>store.userReducer)
 const [requestLectureModel,setLectureRequestModel]=useState(false)

    return (
      <div className='Live'>
        <Navbar activeMenuItem={'live'} />
        <div className='container mb-5'>
     
        <h6 className='d-flex align-items-center justify-content-end'>Missed today's lecture? <button
         style={{'padding':'0px 8px 0px 8px',
         'background': 'linear-gradient(to right, #fb7b49, #edaf38)',
         'color': 'aliceblue',
         'border':'none',
         'borderRadius':'8px',
         'marginLeft':'4px'
      }}onClick={()=>setLectureRequestModel(true)}>Request a lecture</button> </h6>
     
        {/* Using Bootstrap's grid system to make the content responsive */}
        <div> {/* <div className="col-md-8 m-5 order-1 order-md-0"> Changed to col-md-8 */}
      <h3>Upcoming Live Classes</h3>
   
        {!isAuthenticated? <DummyCards/>
         : <StudentCard/>}
      
          <div className="row ">
      </div>


      {requestLectureModel && (
        <div className="model-overlay">
          <div className="model">
          
            <ForgotLectures closeModal={() => setLectureRequestModel(false)} />
          </div>
        </div>
      )}
    </div>
            <Footer />
          </div>
        </div>
   
    );
  
};

export default Live;
