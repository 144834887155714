import React, { useEffect, useState } from "react";

import "../styles/Cards.css";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getMeetings } from "../redux/actions/meetingActions";
import {
  formatDate,
  getMinuteDifference,
  StandardTime,
  calculateCountdown,
} from "../functions/date";

import maleTeacher from "../images/front-view-man-classroom.jpg";

import LivePlans from "./LivePlans";
import "../styles/LivePlans.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const StudentCard = (props) => {
  const [paymentModel, setPaymentModel] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { meetings } = useSelector((store) => store.meetingReducer);

  const { isAuthenticated } = useSelector((store) => store.userReducer);
  useEffect(() => {
    if (!meetings) {
      // Check if meetings is null or undefined
      dispatch(getMeetings());
    }
  }, [dispatch, meetings]); // Include meetings in the dependency array

  const token=jwtDecode(Cookies.get('userJWT'))

  //console.log('first', token?.standard)

  const standard=token?.standard;
  const isPaid = true;
  // console.log('meetings', meetings?.data?.meeting);
  const meet = meetings?.data?.meeting
    ? [...meetings.data.meeting].reverse()
    : [];
  const meeetingData = meet;

  const currentTime = new Date().getTime();
  const activeMeetings = meeetingData?.filter(
    (meeting) => new Date(meeting.endTime).getTime() > currentTime
  );

  const meetingByClass=activeMeetings.filter?.((item)=> standard == item.class)

  console.log('by class', meetingByClass)

  if (!meetingByClass || meetingByClass.length === 0) {
    return (
      <div className="custom-card col-md-6 mb-5 mt-5">
        <div className="custom-flex">
          <div className="custom-body">
            <p className="custom-text">
              Currently there are no live classes are scheduled
            </p>
          </div>
        </div>
      </div>
    );
  }

  const startTime = new Date(meeetingData[4].startTime).getTime();

  const date = new Date(startTime);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "pm" : "am";
  const formattedTime = `${hours % 12 || 12}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  const handleJoinClick = async (ID) => {
    if (!isPaid) {
      setPaymentModel(true);
    } else {
     
 
    const studentMeetIdToView = await meetingByClass.find((meet) => meet.id === ID);
    navigate(`/live/lobby/${ID}`, { state: { studentMeetData: studentMeetIdToView } });

    }
  };

  

  return (
    <>
      {meetingByClass.map((item, index) => {
        const differenceMinutes = getMinuteDifference(
          item?.startTime,
          item?.endTime
        );
        const startTimeMillis = new Date(item?.startTime).getTime();
        const endTimeMillis = new Date(item?.endTime).getTime();
        const { label, disabled, color } = calculateCountdown(
          startTimeMillis,
          endTimeMillis
        );

        // Start time in milliseconds
      //   return (
      //     <div key={index} className="custom-card col-md-6 m-3">
      //       <div className="custom-flex">
      //         <div className="custom-image">
      //           <img src={maleTeacher} className="custom-img" alt="Card" />
      //         </div>
      //         <div className="custom-body">
      //           <div className="custom-date">
      //             {" "}
      //             {formatDate(item?.date)} | {differenceMinutes}mins{" "}
      //           </div>
      //           <h5 className="custom-title">
      //             {item?.chapter} | {item?.subject}
      //           </h5>
      //           <p className="custom-text">{item?.createdBy}</p>
      //           <p className="custom-class">{item?.class}</p>
      //           {/* {currentTime > startTimeMillis ?(
      //             <Link className="custom-btn"  to={item?.url}>Join Now</Link>
      //           ):`Classes will be starting from ${StandardTime(startTimeMillis)}`} */}
      //           <span style={{ color }}>{label}</span>

      //           {!disabled && (
      //             <button
      //               className={`custom-btn ${color}`}
      //               onClick={() => handleJoinClick(item)}
      //             >
      //               JOIN
      //             </button>
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //   );
      // })}


        // Start time in milliseconds
        return (
          <div key={index} className="custom-card col-md-6 m-3">
            <div className="custom-flex">
              <div className="custom-image">
                <img src={maleTeacher} className="custom-img" alt="Card" />
              </div>
              <div className="custom-body">
                <div className="custom-date">
                  {" "}
                  {formatDate(item?.date)} | {differenceMinutes}mins{" "}
                </div>
                <h5 className="custom-title">
                  {item?.chapter} | {item?.subject}
                </h5>
                <p className="custom-text">{item?.createdBy}</p>
                <p className="custom-class">{item?.class}th</p>
                {/* {currentTime > startTimeMillis ?(
                    <Link className="custom-btn"  to={item?.url}>Join Now</Link>
                  ):`Classes will be starting from ${StandardTime(startTimeMillis)}`} */}
                <span style={{ color }}>{label}</span>
  
                {!disabled && (
                  <button
                    className={`custom-btn ${color}`}
                    onClick={() => handleJoinClick(item?.id)}
                  >
                    JOIN
                  </button>
                )}
{/* 
{!disabled && (
                  <button
                    className={`custom-btn ${color}`}
                    onClick={() => window.open(item?.viewer_url, '_blank')}
                  >
                    JOIN
                  </button>
                )} */}
              </div>
            </div>
          </div>
        );
   
    })}

      {paymentModel && (
        <div className="model-overlay" >
          <div className="model">
          
            <LivePlans closeModal={() => setPaymentModel(false)} />
          </div>
        </div>
      )}
    </>
  );
};

export default StudentCard;
