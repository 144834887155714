import axios from "axios";
import Cookies from "js-cookie";
import config from "../../../config";

// for live server
// const API = axios.create({
//   baseURL: 'https://api.enroutingcareers.com/',
// });

//for dev server


// const API = axios.create({
//     baseURL: 'http://localhost:8080',
//   });


const API = axios.create({
   baseURL: config.BASE_URL,
 
});



API.interceptors.request.use((req) => {
  if (Cookies.get('userJWT')) {
    req.headers['x-access-token'] = Cookies.get('userJWT');
  }
  return req;
});


// User Routes
export const getUser = () => API.get("/api/user/profile/get");
export const login = (loginData) => API.post("/api/auth/signin", loginData);
export const signup = (signupData) => API.post("/api/auth/signup", signupData);
export const updateUserDetails = (updates) =>
  API.put("/api/user/update", updates);

console.log("login", login);

// Forgot password
export const forgotPasswordEmail = (formData) =>
  API.post("/api/auth/forgotpassword", formData);

// Reset password
export const resetPassword = (formData) =>
  API.post("/api/auth/newpassword", formData);

// Courses Routes
export const getAllCourses = () => API.get("/admin/courses/all");
export const getUserCourses = () => API.get("/api/courses/self");

// Board Routes
export const getAllBoards = () => API.get("/admin/boards/all");

// Standard Routes
export const getAllStandards = () => API.get("/admin/standards/all");

// Testimonial Routes
export const getAllTestimonials = () => API.get("/api/testimonials");

// Event Routes
export const getAllEvents = () => API.get("/api/events/all");
export const getUserEvents = () => API.get("/api/events/self");
export const makeSubmission = (id, formData) =>
  API.post(`/api/submit/${id}`, formData);
export const editSubmission = (id, formData) =>
  API.post(`/api/submit/edit/${id}`, formData);

export const checkSubmission = (eventId) =>
  API.get(`/api/user/submission/${eventId}`);

// Workshop Routes
export const getAllWorkshops = () => API.get("/api/workshops/all");
export const getUserWorkshops = () => API.get("/api/workshops/self");

// Blog Routes
export const getAllBlogs = () => API.get("/api/blogs/all");

// Help email Routes
export const helpMail = (formData) => API.post("/api/connect", formData);

// Payment Routes
export const buyCourse = (id, formData) =>
  API.post(`/api/course/purchase/${id}`, formData);
export const buyEvent = (id, formData) =>
  API.post(`/api/event/purchase/${id}`, formData);
export const buyWorkshop = (id) => API.post(`/api/workshop/purchase/${id}`);
export const verifyCoursePayment = (paymentData) =>
  API.post("/course/verify-payment", paymentData);
export const verifyEventPayment = (paymentData) =>
  API.post("/event/verify-payment", paymentData);
export const verifyWorkshopPayment = (paymentData) =>
  API.post("/workshop/verify-payment", paymentData);

//

export const scheduleMeeting = (date, time) =>
  axios.post("ZEGO_CLOUD_API_ENDPOINT", {
    date,
    time,
    // Other required parameters
  });

//

export const teacher_login = (teacherloginData) =>
  API.post("/api/teacher/signin", teacherloginData);
export const getTeacher = () => API.get("/api/teacher/profile/get");

//meeting

export const getAllMeetings = () => API.get("/api/meeting/getMeeting");
export const studentRequests = (formData) =>
  API.post("api/user/createStudentQuery", formData);

export const getLectureReq = (teacherId) =>
  API.put(`/api/user/getStudentQueryData?teacherId=${teacherId}`);

export const getAllSubjects = () => API.get("/admin/courses/all");




export const parents_login = (parentLoginData) => API.post("/api/auth/parentSignin", parentLoginData);
export const parent_signup=(signupData)=>API.post("/api/auth/parentSignup",signupData)


export const fetchAllExams=()=>API.get("/api/exam/findAll")



  export const getAttendance = (userId, examId) => API.get("/api/userAttendExam/filter", {params: {
        userId,
        examId
      }
    });


  export const getRankings=(examId)=>API.get(`/api/userAttendExam/attended-users/${examId}`)