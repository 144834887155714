import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Container, Box, Typography, useMediaQuery } from "@mui/material";
import TableRank from "./Table";
import {RankingDropDown} from "./ParentDropdown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { userRankings } from "../../redux/actions/getStudentActions";

export const Ranking = () => {
  // Create a theme instance to access breakpoints
  const theme = createTheme();
  const dispatch=useDispatch()
 


  // Detect if the current screen width is below the 'sm' breakpoint (mobile devices)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <div className="slate">
        <Navbar />

        <Container sx={{ mt: 10 }}>
          {/* Flex container to hold the title and dropdown */}
          <Box
            display="flex"
            // On mobile, stack items vertically; otherwise, horizontally
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
            my={4}
            // Add spacing between items on mobile
            sx={{ gap: isMobile ? 2 : 0 }}
          >
            <Typography variant="h4" component="h2" color="black">
              Student Ranking
            </Typography>

            {/* Wrap ParentDropdown to control its width */}
            <Box
              // On mobile, make the dropdown full width; otherwise, auto
              sx={{ width: isMobile ? "100%" : "auto" }}
            >
              {/* Assuming ParentDropdown accepts a 'fullWidth' prop */}
              <RankingDropDown fullWidth={isMobile} />
            </Box>
          </Box>

          {/* TableRank component */}
          <TableRank />
        </Container>
      </div>
    </ThemeProvider>
  );
};
