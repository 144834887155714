// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Zoom.css */
.room-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 30px;
}

.fancy-btn {
  font-size: 1.25rem;
  padding: 15px 35px !important;
  border: none;
  border-radius: 50px !important;
  background-color: #ffb53e !important; /* Yellow-orange gradient */
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  
}

.fancy-btn:hover {
  background-color: #ff9900;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  color: white !important;
}

#zmmtg-root {
  display: none; /* Hide Zoom container until meeting starts */
}

.image-container {
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;
  
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Ensure the image scales properly */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Zoom.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,qDAAqD;EACrD,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;EACZ,8BAA8B;EAC9B,oCAAoC,EAAE,2BAA2B;EACjE,YAAY;EACZ,yCAAyC;EACzC,yBAAyB;;AAE3B;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,yCAAyC;EACzC,uBAAuB;AACzB;;AAEA;EACE,aAAa,EAAE,6CAA6C;AAC9D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;;EAEf,aAAa,EAAE,2BAA2B;EAC1C,iBAAiB,EAAE,qCAAqC;EACxD,mBAAmB;EACnB,yCAAyC;AAC3C","sourcesContent":["/* Zoom.css */\n.room-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background: linear-gradient(135deg, #f8f9fa, #e9ecef);\n  text-align: center;\n  padding: 20px;\n}\n\nh1 {\n  font-size: 2.5rem;\n  color: #343a40;\n  margin-bottom: 30px;\n}\n\n.fancy-btn {\n  font-size: 1.25rem;\n  padding: 15px 35px !important;\n  border: none;\n  border-radius: 50px !important;\n  background-color: #ffb53e !important; /* Yellow-orange gradient */\n  color: white;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n  transition: all 0.3s ease;\n  \n}\n\n.fancy-btn:hover {\n  background-color: #ff9900;\n  transform: translateY(-2px);\n  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);\n  color: white !important;\n}\n\n#zmmtg-root {\n  display: none; /* Hide Zoom container until meeting starts */\n}\n\n.image-container {\n  margin-bottom: 20px;\n}\n\n.image-container img {\n  max-width: 100%;\n  \n  height: 200px; /* Set the desired height */\n  object-fit: cover; /* Ensure the image scales properly */\n  border-radius: 10px;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
