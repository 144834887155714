import { PARENTS_SIGN_IN,PARENT_LOGOUT,PARENTS_SIGN_UP } from '../actionsType';
import Cookies from 'js-cookie';

const initialState = {
  parentsData: null,
  isAuthenticated: false,
};

const parentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARENTS_SIGN_IN:
      Cookies.set('userJWT', action?.payload?.token, {
        expires: 7,
      });
      return {
        ...state,
        parentsData: action?.payload?.parentsDetails,
        isAuthenticated: true,
      };
      case PARENTS_SIGN_UP:
      Cookies.set('userJWT', action?.payload?.token, {
        expires: 7,
      });
      return {
        ...state,
        parentsData: action?.payload?.parentsDetails,
        isAuthenticated: true,
      };


      case PARENT_LOGOUT:
      Cookies.remove('userJWT');
      return initialState;



      
    default:
        return state;
    }
}


export default parentReducer;