// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChapterCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 500;
  border-bottom: 1px solid #d1d9d9;
  background-color: #ffffff;
}

.ChapterCard-left {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2.5rem;
}
.ChapterCard-left p {
  width: 60%;
}

.ChapterCard-right {
  color: #3eac72;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2.5rem;
}
.ChapterCard-right button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.6rem 2.2rem;
  border-radius: 2rem;
  font-weight: 500;
}

.unlocked-icons {
  margin-left: 0.5rem;
  width: 14px;
  margin-bottom: -3px;
}

.lock-icon {
  width: 1.9rem;
}

.read-watch-button {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ChapterCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;AACxB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,eAAe;EACf,cAAc;EACd,uDAAuD;EACvD,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".ChapterCard {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 0.9rem;\n  font-weight: 500;\n  border-bottom: 1px solid #d1d9d9;\n  background-color: #ffffff;\n}\n\n.ChapterCard-left {\n  width: 40%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.8rem 2.5rem;\n}\n.ChapterCard-left p {\n  width: 60%;\n}\n\n.ChapterCard-right {\n  color: #3eac72;\n  width: 30%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.8rem 2.5rem;\n}\n.ChapterCard-right button {\n  border: none;\n  cursor: pointer;\n  color: #ffffff;\n  background: linear-gradient(to right, #fb794a, #edb038);\n  padding: 0.6rem 2.2rem;\n  border-radius: 2rem;\n  font-weight: 500;\n}\n\n.unlocked-icons {\n  margin-left: 0.5rem;\n  width: 14px;\n  margin-bottom: -3px;\n}\n\n.lock-icon {\n  width: 1.9rem;\n}\n\n.read-watch-button {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
